import React, { useEffect } from 'react';
import './Centium.css';
import { useCsv } from '../../hooks/useCsv';
import { CsvForm } from './CsvForm/CsvForm';
import { csvStringToArrrayOfObjects } from './utils';
import { Game } from './Game/Game';

export interface Row {
    number: number | undefined;
    word: string | undefined;
}

export function Centium(): React.ReactElement {
    const [csvFile, handleUpload] = useCsv();
    const [csvFileSubmited, setCsvFileSubmited] = React.useState(false);
    const [csvFileContent, setCsvFileContent] = React.useState<Row[]>([]);

    useEffect(() => {
        if (csvFile != null) {
            const reader = new FileReader();
            reader.onload = (e) => {
                if (e.target != null) {
                    try {
                        setCsvFileContent(csvStringToArrrayOfObjects(e.target.result as string));
                    } catch (e) {
                        alert(e);
                    }
                }
            };
            reader.readAsText(csvFile);
        }
    }, [csvFile]);

    return (
        <div className={'Centium-content'}>
            {!csvFileSubmited ? (
                <CsvForm
                    onSubmit={(e) => {
                        e.preventDefault();
                        setCsvFileSubmited(!csvFileSubmited);
                    }}
                    onChange={(e) => handleUpload(e)}
                    csvFile={csvFile}
                />
            ) : (
                <div className={'Centium-content'}>
                    <Game csvFileContent={csvFileContent} />
                </div>
            )}
        </div>
    );
}
